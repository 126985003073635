<template>
  <v-dialog v-model="show" activator="parent" width="70%">
    <v-card v-if="product.name" class="modal" :dark="$dark.get()">
      <v-card-title class="flex justify-center">
        Информация о продукте
      </v-card-title>
      <v-card-text>
        <div v-if="product.images.length" class="modal_img">
          <v-img
            v-for="img in product.images"
            :key="img.fileUrl"
            :src="img.fileUrl"
            max-width="100"
            max-height="100"
            class="mb-5"
          ></v-img>
        </div>

        <p>
          <span class="font-weight-bold">Название : </span> {{ product.name }}
        </p>
        <p v-if="product.shortDescription">
          <span class="font-weight-bold">Короткое описание : </span>
          {{ product.shortDescription }}
        </p>
        <p>
          <span class="font-weight-bold">Описание : </span>
          {{ product.description }}
        </p>
        <p>
          <span class="font-weight-bold">Штрихкод : </span>
          {{ product.barcodes ? product.barcodes.join(", ") : "Не указан" }}
        </p>
        <p><span class="font-weight-bold">Guid : </span> {{ product.guid }}</p>
        <p>
          <span class="font-weight-bold">Id меры : </span>
          {{ product.measureId }}
        </p>
        <p v-if="product.categoryId">
          <span class="font-weight-bold">Категория : </span>
          {{ product.categoryId.name }}
        </p>
      </v-card-text>
      <v-card-actions class="modal__actions">
        <v-btn @click="show = false">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "InfoModalMVP",
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      show: false,
    };
  },
  watch: {
    showModal() {
      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__actions {
    width: 100%;
    justify-content: right;
  }
  &_img {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
    justify-content: center;
    height: max-content;
  }
}
</style>
